import { useAppStore, UserStore } from '@/stores';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebaseService';

export class AuthService {
	static async login(email: string, password: string) {
		const userStore = UserStore();
		var response = await signInWithEmailAndPassword(auth, email, password);
		userStore.setUser(response.user);
	}

	static async logout() {
		await auth.signOut();
	}

	static async waitForAuthInit() {
		let unsubscribe = null as any;
		await new Promise<void>((resolve) => {
			unsubscribe = auth.onAuthStateChanged((_) => resolve());
		});
		(await unsubscribe!)();
	}

	async authorizeFlow() {
		const userStore = UserStore();
		const appStore = useAppStore();

		auth.onAuthStateChanged(async (response) => {
			if (response) {
				userStore.setUser(response);
				var result = await auth?.currentUser?.getIdTokenResult(true);
				if (result) userStore.setClaims(result.claims);
			}
			if (!response) userStore.showLogin();

			appStore.setAppStatus(true);
		});
	}
}
