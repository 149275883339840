import { FileExcelOutlined } from '@vicons/antd';
import { DocumentAdd16Regular, DocumentArrowUp16Regular, DocumentCheckmark20Regular, DocumentPdf16Regular, Filter16Regular, MoreCircle32Filled, Send28Regular } from '@vicons/fluent';
import { CardOutline, ChevronDown, Folder, FolderOpenOutline, GridOutline, LogOutOutline as LogoutIcon, StorefrontOutline } from '@vicons/ionicons5';
import {
	AccessTimeFilled,
	AnalyticsOutlined,
	AssignmentOutlined,
	CheckCircleOutlineOutlined,
	DarkModeOutlined,
	DeleteTwotone,
	DoubleArrowRound,
	FileDownloadOutlined,
	FolderOutlined,
	GroupOutlined,
	InfoTwotone,
	LightModeOutlined,
	LockResetFilled,
	MessageTwotone,
	MoreVertTwotone,
	OpenInNewRound,
	PendingOutlined,
	PercentFilled,
	PersonAddAlt1Outlined,
	PixRound,
	SecurityTwotone,
	SettingsTwotone,
	UpdateRound,
} from '@vicons/material';
import {
	AdjustmentsHorizontal,
	AlertTriangle,
	Bell,
	Books,
	BrandWhatsapp,
	Building,
	Check,
	Edit,
	ExternalLink,
	FileInvoice,
	FileReport,
	Folders,
	Home2,
	Inbox,
	MessageCircleOff,
	ReceiptRefund,
	RefreshDot,
	ReportMoney,
	Search,
} from '@vicons/tabler';

export const Icons = {
	Sheets: FileExcelOutlined,
	ChevronDown: ChevronDown,
	GridOutline: GridOutline,
	Proposal: DocumentArrowUp16Regular,
	NewProposal: DocumentAdd16Regular,
	Contract: DocumentCheckmark20Regular,
	GroupOutlined: GroupOutlined,
	Simulator: AdjustmentsHorizontal,
	AnalyticsOutlined: AnalyticsOutlined,
	AssignmentOutlined: AssignmentOutlined,
	MortgageProduct: Building,
	Logout: LogoutIcon,
	User: PersonAddAlt1Outlined,
	FolderOutlined: FolderOutlined,
	FolderOpen: FolderOpenOutline,
	PendingOutlined: PendingOutlined,
	Done: CheckCircleOutlineOutlined,
	Download: FileDownloadOutlined,
	Open: ExternalLink,
	Home: Home2,
	Commercial: StorefrontOutline,
	Percent: PercentFilled,
	CreditCard: CardOutline,
	Invoice: FileInvoice,
	Pix: PixRound,
	Invoiced: FileReport,
	DarkMode: DarkModeOutlined,
	LightMode: LightModeOutlined,
	Label: Folder,
	Inbox: Inbox,
	Settings: SettingsTwotone,
	Notification: Bell,
	Security: SecurityTwotone,
	More: MoreCircle32Filled,
	Message: MessageTwotone,
	Info: InfoTwotone,
	MoreVert: MoreVertTwotone,
	Delete: DeleteTwotone,
	ResetPassword: LockResetFilled,
	View: OpenInNewRound,
	Send: Send28Regular,
	StatusToArrow: DoubleArrowRound,
	Alert: AlertTriangle,
	Checkmark: Check,
	MessageEmpty: MessageCircleOff,
	UpdateTimer: UpdateRound,
	Edit: Edit,
	Search,
	Waiting: AccessTimeFilled,
	Product: Folders,
	Whatsapp: BrandWhatsapp,
	Update: RefreshDot,
	Financial: ReportMoney,
	ReportPDF: DocumentPdf16Regular,
	ReceiptRefund,
	Legal: Books,
	Filter: Filter16Regular,
};

export default Icons;
