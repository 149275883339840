import { GeneralService } from '@/services';
import type { SelectBaseOption } from 'naive-ui/es/select/src/interface';
import { defineStore } from 'pinia';

export const useAppStore = defineStore('appStore', {
	state: () => ({
		caseStatus: [] as SelectBaseOption[],
		tableHeight: 480,
		appState: false,
	}),

	actions: {
		async loadCaseStatus() {
			const response = await await GeneralService.getEnumItems('CaseStatus');
			this.caseStatus = response.data;
		},

		setTableHeight() {
			this.tableHeight = window.innerHeight - 260;
		},

		setAppStatus(status: boolean) {
			console.log('Setting app status to: ', status);
			this.appState = status;
		},
	},

	getters: {},
});

export default useAppStore;
