import { MoneyHelper } from '@/utils/MoneyHelper';
import axios from 'axios';
import { MIN_FINANCIAL_VALUE } from '.';

export class Messages {
  static readonly ERROR_IS_REQUIRED = 'Por favor, preencha este campo';
  static readonly ERROR_IS_SELECT_REQUIRED = 'Por favor, selecione uma opção';
  static readonly ERROR_IS_FILE_REQUIRED = 'Por favor, selecione um arquivo';
  static readonly ERROR_INVALID_EMAIL = 'O email digitado é inválido';
  static readonly ERROR_INVALID_DOCUMENT = 'O documento digitado é inválido';
  static readonly ERROR_INVALID_LOGIN = 'Email ou senha inválido';
  static readonly ERROR_API_CONNECTION = 'Houve um erro ao conectar a API';
  static readonly ERROR_API_BAD_REQUEST = 'Houve um erro ao processar sua solicitação';
  static readonly ERROR_API_INVALID_CREDENTIALS = 'Sua sessão expirou';
  static readonly ERROR_NUMBER_GREATER_THAN_ZERO = 'O valor precisa ser maior que zero';

  static readonly LOGIN_LOADING = 'Verificando credenciais';
  static readonly LOGIN_SUCCESS = 'Ok! Entrando...';
  static readonly MODAL_ERROR_TITLE = 'Ocorreu um erro';
  static readonly ERROR_INVALID_DATE = 'A data digitada é inválida';
  static readonly ERROR_OVER_18_YEARS_OLD = 'Idade mínima permitida é de 18 anos';
  static readonly ERROR_MINIMAL_OPENING_DATE = 'Data mínima permitida é de 2 anos';
  static readonly ERROR_OVER_65_YEARS_OLD = 'Idade máxima permitida é de 65 anos';
  static readonly ERROR_OVER_80_YEARS_OLD = 'Idade máxima permitida é de 78 anos';
  static readonly ERROR_SCORE_BETWEEN_0_AND_1000 = 'O score deve estar entre 0 e 1000';
  static readonly ERROR_INVALID_PHONE = 'O número digitado é inválido';
  static readonly ERROR_INVALID_NAME = 'O nome deve ser completo';
  static readonly ERROR_INVALID_ARRAY_IS_EMPTY = 'Por favor crie pelo menos um item a lista';
  static readonly ERROR_DATE_GREATER_THAN_YESTERDAY = 'A data deve ser maior que ontem';
  static readonly ERROR_TERM_MIN = 'O prazo mínimo para financiamento é de 36 meses';
  static readonly ERROR_TERM_BUYER_OVER_LIMIT =
    'Essa operação é inválida pois a idade do comprador mais velho ultrapassa o limite de 80 anos e 6 meses para o financiamento';
  static ERROR_TERM_MAX = (term: number) => `O prazo máximo para finaciamento é de ${term} meses`;
  static readonly ERROR_FINANCING_VALUE_SHOULD_BE_LOWER =
    'A soma do FGTS + Entrada deve ser menor que o valor do imóvel';
  static ERROR_FINANCING_VALUE_SHOULD_BE_GREATER = (minimumFinancingValue: number) =>
    `O valor mínimo para entrada é de ${MoneyHelper.toMoneyString(minimumFinancingValue)}`;
  static ERROR_FINANCING_VALUE_MIN = (minimumFinancingValue: number) =>
    `O valor mínimo para financiamento é de ${MoneyHelper.toMoneyString(minimumFinancingValue)}`;
  static ERROR_FINANCING_OWN_RESOURCES_REQUIRED = `A soma da entrada + FTGS deve ser maior que zero`;
  static ERROR_NAME_MINIMAL_LENGTH = `O nome deve ter no mínimo 1 nome e 1 sobrenome`;

  static MODAL_UPDATE_TITLE(s: string) {
    return `Atualizando ${s}`;
  }

  static MODAL_ERROR_CONTENT(err: unknown, action: string) {
    const baseErrorMessage = `Não foi possível ${action}`;

    if (axios.isAxiosError(err)) {
      const errorMessage = err.response?.data.errorMessage;
      return (
        <div>
          {baseErrorMessage}
          <br />
          Motivo: <b>{errorMessage}</b>
        </div>
      );
    }

    return <div>{baseErrorMessage}</div>;
  }
}
