import type {
  IBank,
  ICaseLogResponse,
  ICaseSumarizedItem,
  IGridifyPaging,
  ICase,
  IResponseBase,
} from '@/interfaces';
import type { ICaseRequest } from '@/interfaces/ICaseRequest';
import type { ICaseResponse } from '@/interfaces/IProposalResponse';
import type { IGridifyQuery } from '@/models';
import { Http, HttpService } from './http';
import type { CaseType, ProposalStatus } from '@/constants';
import type { CaseResponseType } from '@/models/CaseResponseType';

const http = Http.create();

export class CaseService extends HttpService {
  static async updateCase(value: ICaseResponse) {
    const response = await http.patch(`/cases/${value.id}`, value);
    return response.data;
  }
  /**
   * Create a new proposal
   * @param proposalRequest
   * @returns
   */
  static async addCase(proposalRequest: ICaseRequest) {
    const response = await http.post<IResponseBase<ICase>>('/cases', proposalRequest);
    return response.data;
  }

  /**
   * Return a pagination of proposals
   * @param query
   * @returns
   *
   */
  static async getCases(query: IGridifyQuery | null = null) {
    const response = await http.get<IGridifyPaging<ICase>>('/cases', {
      params: query,
    });
    return response.data;
  }

  static async getCasesV2(query: IGridifyQuery | null = null, view: string = '') {
    const response = await http.get<IGridifyPaging<CaseResponseType>>(`v2/cases/${view}`, {
      params: query,
    });

    return response.data;
  }

  static async getCasesSumarized(query: IGridifyQuery | null = null) {
    const cancelToken = this.createCancelToken('getCasesSumarized');

    const response = await http.get<IResponseBase<ICaseSumarizedItem[]>>('/cases/sumarized', {
      params: query,
      cancelToken: cancelToken,
    });

    this.cancelTokens['getCasesSumarized'] = null;

    return response.data;
  }

  /**
   * Return a proposal by id
   * @param id
   * @param complete Include nested values (Quotation, Product, Renter)
   * @returns
   */
  static async getCaseById(id: string, complete: boolean = false) {
    const response = await http.get<IResponseBase<ICaseResponse>>(
      `cases/${id}?complete=${complete}`,
    );
    return response.data;
  }

  static async cancelCase(caseId: string, reason: string) {
    const response = await http.post<IResponseBase<ICaseResponse>>(`cases/${caseId}/cancel`, {
      caseId,
      reason,
    });
    return response.data;
  }

  static async getCaseLogs(id: string, query: IGridifyQuery) {
    const response = await http.get<IGridifyPaging<ICaseLogResponse>>(`cases/${id}/logs`, {
      params: query,
    });
    return response.data;
  }

  static async updateStatus(id: string, status: ProposalStatus) {
    const response = await http.patch<IResponseBase<ICaseResponse>>(`cases/${id}/status/${status}`);
    return response.data;
  }

  static getDocumentFiller(caseId: string, bankId: string) {
    return `${import.meta.env.VITE_API_SERVICE}/documentfiller/cases/${caseId}/fill/${bankId}`;
  }
}

export default CaseService;
