import { computed, defineComponent } from 'vue';
import { darkTheme, NConfigProvider, NDialogProvider, NLayout, NLayoutContent, NMessageProvider, ptBR, datePtBR, NThemeEditor } from 'naive-ui';
import { RouterView } from 'vue-router';

import { AppHeader, AppLogin } from './components/App';
import { useAppStore, UserStore } from './stores';
import { AuthService } from './services';
import { darkThemeOverrides } from './themes/dark';
import { lightThemeOverrides } from './themes/light';

import './services/amplitudeService';

import { useFeatBitStore } from './services/featbitService';
import { vMaska } from 'maska/vue';

export const App = defineComponent({
	directives: { maska: vMaska },
	setup() {
		const userStore = UserStore();
		const appStore = useAppStore();

		const user = computed(() => {
			return userStore.user;
		});

		const theme = computed(() => {
			return userStore.appTheme == 'light' ? null : darkTheme;
		});

		const themeOverride = computed(() => {
			return userStore.appTheme == 'light' ? lightThemeOverrides : darkThemeOverrides;
		});

		const showLogin = computed(() => {
			return userStore.showLoginPrompt;
		});

		const isGlobalSearchEnabled = computed(() => {
			const featbit = useFeatBitStore();
			return featbit.flags['global-search'] as boolean;
		});

		const appState = computed(() => {
			return appStore.appState;
		});

		appStore.setTableHeight();

		window.onresize = () => {
			useAppStore().setTableHeight();
		};

		return {
			locale: ptBR,
			dateLocale: datePtBR,
			user,
			theme,
			showLogin,
			themeOverride,
			isGlobalSearchEnabled,
			appState,
		};
	},

	mounted() {
		const auth = new AuthService();
		auth.authorizeFlow();
	},

	render() {
		if (!this.appState) return;

		return (
			<NConfigProvider style="height: calc(100vh); display: flex; flex-direction: column;" theme={this.theme} locale={this.locale} dateLocale={this.dateLocale} theme-overrides={this.themeOverride}>
				<NDialogProvider>
					<NMessageProvider>
						{!this.showLogin && (
							<NLayout content-style="height: calc(100vh) !important; display: flex; flex-direction: column;">
								<AppHeader />
								<NLayoutContent class="app-base-layout">
									<RouterView></RouterView>
								</NLayoutContent>
							</NLayout>
						)}

						{this.showLogin && (
							<NLayout class="app-base-layout" position="absolute">
								<AppLogin />
							</NLayout>
						)}
					</NMessageProvider>
				</NDialogProvider>
			</NConfigProvider>
		);
	},
});

export default App;
