import { UserRoles } from '@/constants';
import type { INotification } from '@/interfaces';
import { HubsService, UserService } from '@/services';
import amplitude from '@/services/amplitudeService';
import { featbitService } from '@/services/featbitService';
import type { User } from 'firebase/auth';
import { defineStore } from 'pinia';
import hj from '@hotjar/browser';

export const UserStore = defineStore('user', {
	state: () => ({
		user: null as User | null,
		showLoginPrompt: true,
		userClaims: {
			Role: '',
			BrokerId: '',
			Email: '',
			Document: '',
			Id: '',
			Name: '',
			Phone: '',
			aud: '',
			auth_time: '',
			email: '',
			email_verified: '',
			exp: '',
			firebase: '',
			iss: '',
			name: '',
			sub: '',
			user_id: '',
		},
		//@ts-ignore
		theme: localStorage.getItem('theme') || 'light',
		notifications: new Array<INotification<any>>(),
	}),

	actions: {
		setUser(user: User) {
			this.showLoginPrompt = false;
			this.user = user;
		},

		showLogin() {
			this.showLoginPrompt = true;
		},

		setClaims(claims: any) {
			this.userClaims = claims;

			amplitude.setUserId(claims.Id);

			const identifyEvent = new amplitude.Identify();
			identifyEvent.set('brokerId', claims.BrokerId);

			amplitude.identify(identifyEvent);
			amplitude.setGroup('role', claims.Role);

			hj.init(3614991, 6);

			hj.identify(claims.Id, {
				brokerId: claims.BrokerId,
			} as any);

			featbitService.init({
				keyId: claims.Id,
				name: claims.Email,
			});
		},

		setUserTheme(theme: string) {
			//@ts-ignore
			localStorage.setItem('theme', theme);
		},

		toggleTheme() {
			this.theme = this.theme === 'light' ? 'dark' : 'light';
			this.setUserTheme(this.theme);
		},

		async getNotifications() {
			var response = await UserService.getNotifications();
			this.setNotifications(response.data);
		},

		async initNofiicationsHub() {
			var hub = await HubsService.initNoficationsHub();
			this.getNotifications();
			hub.onNotificationReceived(() => this.getNotifications());
		},

		async readNotifications() {
			if (this.hasUnreadNotification) {
				var response = await UserService.readNotifications();
				this.setNotifications(response.data);
			}
		},

		setNotifications(notifications: INotification<any>[]) {
			this.notifications = notifications;

			// @ts-ignore
			var title = window.document.title.replace(/\([0-9]+\)/g, '');

			if (this.hasUnreadNotification) {
				// @ts-ignore
				window.document.title = `(${this.countUnreadNotifications}) ${title}`;
			} else {
				// @ts-ignore
				window.document.title = title;
			}
		},
	},

	getters: {
		role: (state) => state.userClaims.Role,
		isUserAdmin: (state) => state.userClaims.Role === UserRoles.Admin,
		isUser: (state) => state.userClaims.Role === UserRoles.User,
		isBrokerAdmin: (state) => state.userClaims.Role === UserRoles.BrokerAdmin,
		isBrokerAgent: (state) => state.userClaims.Role === UserRoles.BrokerAgent,
		isBusinessConsultant: (state) => state.userClaims.Role === UserRoles.BUSINESS_CONSULTANT,
		isBusinessManager: (state) => state.userClaims.Role === UserRoles.BUSINESS_MANAGER,
		isBrokerUser: (state) => state.userClaims.Role === UserRoles.BrokerAdmin || state.userClaims.Role === UserRoles.BrokerAgent,
		appTheme: (state) => state.theme,
		hasUnreadNotification: (state) => state.notifications.some((x) => x.readAt == null),
		countUnreadNotifications: (state) => state.notifications.filter((x) => x.readAt == null).length,
	},
});

export default UserStore;
