import { DateHelper } from './DateHelper';

const NUMBER_LOCALE = 'pt-BR';
const NUMBER_PRECISION = 2;
const LEGAL_FEE = 0.1;
const REGISTRY_FEE = 5; // 5% of the property value

export class MoneyHelper {
	static calculateRegistryFees(propertyValue: number) {
		return propertyValue * (REGISTRY_FEE / 100);
	}
	static toMoneyString(value: number | null) {
		if (value == null || value == undefined) value = 0;

		var moneyFormat = new Intl.NumberFormat(NUMBER_LOCALE, {
			style: 'currency',
			currency: 'BRL',
		});

		return moneyFormat.format(value);
	}

	static toHumanFormat(value: number | null) {
		if (value === null) return '';

		var response = value.toLocaleString(NUMBER_LOCALE, {
			maximumFractionDigits: NUMBER_PRECISION,
			minimumFractionDigits: NUMBER_PRECISION,
		});

		return response;
	}

	static isInteger(value: string) {
		return Number.isInteger(parseInt(value));
	}

	static cleanNumber(value: any) {
		let result = '';
		if (value) {
			let flag = false;
			let arrayValue = value.toString().split('');
			for (var i = 0; i < arrayValue.length; i++) {
				if (MoneyHelper.isInteger(arrayValue[i])) {
					if (!flag) {
						if (arrayValue[i] !== '0') {
							result = result + arrayValue[i];
							flag = true;
						}
					} else {
						result = result + arrayValue[i];
					}
				}
			}
		}
		return result;
	}

	static toMachineFormat(value: string) {
		if (value === null || value == '') return null;

		let parsedValue = '';

		if (value) {
			parsedValue = MoneyHelper.cleanNumber(value);
			parsedValue = parsedValue.padStart(NUMBER_PRECISION, '0');
			let size = parsedValue.length;
			parsedValue = parsedValue.substring(0, size - NUMBER_PRECISION) + '.' + parsedValue.substring(size - NUMBER_PRECISION, size);
		}

		return parseFloat(parsedValue);
	}

	static calculateFineOcurrenceFee(value: number, dateA: string, dateB: string) {
		var days = DateHelper.diffBetweenDates(dateA, dateB);

		console.log(days);

		const months = Math.floor(days / 30);
		const remainingDays = days % 30;

		const tax = Math.pow(1 + 0.01, months) * Math.pow(1 + 0.000333, remainingDays);

		return value * tax - value;
	}

	static calculateFineLegalFee(value: number) {
		return value * LEGAL_FEE;
	}
}
