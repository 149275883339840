import { h, resolveComponent } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import Routes from '../utils/routes';
import { UserRoles } from '@/constants/UserRoles';

const LocaleComponent = {
  render() {
    return h(resolveComponent('router-view'));
  },
};

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '',
      name: Routes.Home,
      component: () => import('@/views/Home/Home'),
    },
    {
      path: '/search',
      name: Routes.Search,
      component: () => import('@/views/Search/SearchView'),
    },
    {
      path: '/financial',
      component: LocaleComponent,
      meta: { roles: [UserRoles.User] },
      children: [
        {
          path: '',
          name: Routes.Financial,
          component: () => import('@/views/Financial/FinancialView'),
        },
      ],
    },
    {
      path: '/legal',
      component: LocaleComponent,
      meta: { roles: [UserRoles.User] },
      children: [
        {
          path: '',
          name: Routes.Legal,
          component: () => import('@/views/Legal/LegalView'),
        },
      ],
    },
    {
      path: '/cases',
      redirect: { name: Routes.CasesList },
      name: Routes.CaseRoot,
      component: LocaleComponent,
      children: [
        {
          path: 'real-estate',
          component: LocaleComponent,
          name: Routes.RealEstateFinancingRoot,
          children: [
            {
              path: 'new',
              name: Routes.RealEstateFinancingCreate,
              component: () => import('@/views/Cases/RealEstate/CreateRealEstateFinancingView'),
            },

            {
              path: '',
              name: Routes.RealEstateFinancingCases,
              component: () => import('@/views/Cases/RealEstate/RealEstateCasesView'),
            },
          ],
        },
        {
          path: 'proposals',
          name: Routes.Cases,
          component: () => import('@/views/Cases/CasesView'),
          props: { caseType: 'proposals' },
        },
        {
          path: 'contracts',
          name: Routes.Contracts,
          component: () => import('@/views/Cases/CasesView'),
          props: { caseType: 'contracts' },
        },
        {
          path: 'new',
          name: Routes.CaseCreateView,
          component: () => import('@/views/Cases/CaseCreateView'),
        },
        {
          path: ':caseId/:tab?',
          name: Routes.CaseView,
          component: () => import('@/views/Cases/CaseView'),
        },
      ],
    },

    {
      path: '/simulator',
      name: Routes.Simulator,
      component: () => import('@/views/Simulador/SimuladorView'),
    },
    {
      path: '/management',
      component: LocaleComponent,
      children: [
        {
          path: 'users',
          children: [
            {
              path: '',
              name: Routes.ManagementUsers,
              component: () => import('@/views/Management/Users/UsersView'),
            },
            {
              path: 'new',
              name: Routes.ManagementUserNew,
              component: () => import('@/views/Management/Users/UserFormView'),
            },
            {
              path: ':userId',
              name: Routes.ManagementUserView,
              component: () => import('@/views/Management/Users/UserView'),
            },
          ],
        },

        {
          path: 'brokers',
          component: LocaleComponent,
          children: [
            {
              path: '',
              name: Routes.Brokers,
              component: () => import('@/views/Management/Brokers/BrokersView'),
            },
            {
              path: 'new',
              name: Routes.BrokersNew,
              component: () => import('@/views/Management/Brokers/BrokerFormView'),
            },
            {
              path: ':brokerId',
              component: () => import('@/views/Management/Brokers/BrokerView'),
              children: [
                {
                  path: '',
                  name: Routes.ManagementBrokerView,
                  component: () => import('@/views/Management/Brokers/BrokerDataView'),
                },

                {
                  path: 'credit-policy',
                  name: Routes.ManagementBrokerCreditPolicyView,
                  component: () => import('@/views/Management/Brokers/BrokerCreditPolicyView'),
                },
                {
                  path: 'documents',
                  name: Routes.ManagementBrokerDocumentsView,
                  component: () => import('@/views/Management/Brokers/BrokerDocumentsView'),
                },
              ],
            },
          ],
        },

        {
          path: 'credit-policy',
          name: Routes.ManagementCreditPolicyView,
          component: () => import('@/views/Management/CreditPolicy/CreditPolicyView'),
        },

        {
          path: 'financial',
          name: Routes.ManagementFinancialView,
          component: () => import('@/views/Management/Financial/FinancialManagementView'),
          children: [
            {
              path: 'invoices',
              name: Routes.ManagementFinancialBrokerBill,
              component: () => import('@/views/Management/Financial/BrokerFinancialManagementView'),
            },
            {
              path: 'broker-invoices',
              name: Routes.ManagementFinancialBrokerBills,
              component: () => import('@/views/Management/Financial/BrokerInvoicesManagementView'),
            },
            {
              path: 'payments-requests',
              name: Routes.ManagementFinancialPaymentRequests,
              component: () =>
                import('@/views/Management/Financial/PaymentRequestsFinancialManagementView'),
            },
            {
              path: 'deals',
              name: Routes.ManagementDeals,
              component: () => import('@/views/Management/Financial/DealsFinancialManagementView'),
            },
          ],
        },
      ],
    },
    {
      path: '/properties',
      component: LocaleComponent,
      children: [
        {
          path: '',
          name: Routes.PropertiesView,
          component: () => import('@/views/Properties/PropertiesView'),
        },
      ],
    },

    {
      path: '/notification',
      name: Routes.NotificationsView,
      component: () => import('@/views/Notifications/NotificationsView'),
    },

    {
      path: '/account',
      component: () => import('@/views/Account/AccountView'),
      children: [
        {
          path: '',
          name: Routes.AccountView,
          component: () => import('@/views/Account/AccountInfos'),
        },
        {
          path: 'notifications',
          name: Routes.AccountNotifications,
          component: () => import('@/views/Account/AccountNotifications'),
        },
      ],
    },

    {
      path: '/financial/broker',
      component: () => import('@/views/Financial/Broker/BrokerFinancialView'),
      children: [
        {
          path: '',
          name: Routes.BrokerFinancial,
          component: () => import('@/views/Financial/Broker/BrokerFinancialDashboard'),
        },
        {
          path: 'bill/:year/:month',
          props: true,
          name: Routes.BrokerFinancialBills,
          component: () => import('@/views/Financial/Broker/BrokerFinancialBills'),
        },
      ],
    },
  ],
});

// router.beforeEach((route, _, next) => {
// 	if ('roles' in route.meta) {
// 		const user = UserStore();
// 		var roles = route.meta.roles as string[];

// 		console.log(roles, user.role);
// 		if (roles.includes(user.role)) {
// 			next();
// 		}

// 		next('/');
// 	}
// });

export default router;
